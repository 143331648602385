import { useFrame, useThree } from '@react-three/fiber'
import copy from "copy-to-clipboard"
import { N8AOPostPass } from "n8ao"
import { BloomEffect, EffectComposer, EffectPass, FXAAEffect, LUT3DEffect, LUT3dlLoader, RenderPass, ToneMappingEffect, VignetteEffect } from 'postprocessing'
import { useEffect, useState } from 'react'
import { Pane } from "tweakpane"
import { LensDistortionEffect, MotionBlurEffect, SSREffect, SharpnessEffect, TRAAEffect } from './realism-effects'
import { SSGIDebugGUI } from './realism-effects/SSGIDebugGUI'
import { SSGIEffect, VelocityDepthNormalPass } from './realism-effects/index'
import { toHalfFloat } from 'three/src/extras/DataUtils'
import { HalfFloatType } from 'three'
import { getGPUTier } from 'detect-gpu'

export function Effects() {
  const gl = useThree((state) => state.gl)
  const scene = useThree((state) => state.scene)
  const camera = useThree((state) => state.camera)
  const size = useThree((state) => state.size)
  const [composer] = useState(() => new EffectComposer(gl, { multisampling: 0 }))


  useEffect(() => composer.setSize(size.width, size.height), [composer, size])
  useEffect(() => {
    (async () => {
      const gpuTier = await getGPUTier();

      const { tier } = gpuTier

      let config = {
        "distance": 4.89,
        "thickness": 10,
        "denoiseIterations": 1,
        "denoiseKernel": 3,
        "denoiseDiffuse": 25,
        "denoiseSpecular": 25.54,
        "radius": 3,
        "phi": 0.019999999999999997,
        "lumaPhi": 5.434999999999999,
        "depthPhi": 5.435000000000001,
        "normalPhi": 23.912999999999997,
        "roughnessPhi": 100,
        "specularPhi": 4.690999999999996,
        "envBlur": 0.75,
        "importanceSampling": true,
        "steps": 25,
        "refineSteps": 4,
        "resolutionScale": 1,
        "missedRays": false
      }

      const velocityDepthNormalPass = new VelocityDepthNormalPass(scene, camera)

      const renderPass = new RenderPass(scene, camera)
      composer.addPass(renderPass)

      if (tier >= 2) {
        composer.addPass(velocityDepthNormalPass)

        let ssgiEffect
        if (tier >= 3) {
          ssgiEffect = new SSGIEffect(composer, scene, camera, { ...config, velocityDepthNormalPass })
        } else {
          ssgiEffect = new SSREffect(composer, scene, camera, { ...config, velocityDepthNormalPass, denoiseMode: "full_temporal" })
        }

        const gui2 = new SSGIDebugGUI(ssgiEffect, config)
        gui2.pane.containerElem_.style.left = "8px"
        gui2.pane.containerElem_.style.top = "56px"

        composer.addPass(new EffectPass(camera, ssgiEffect))
      }

      // N8AO
      if (tier >= 1) {
        const n8aopass = new N8AOPostPass(
          scene,
          camera,
          size.width,
          size.height
        );
        composer.addPass(n8aopass)

        const n8aoProps = {
          "aoSamples": 8,
          "aoRadius": 8.42,
          "denoiseSamples": 8,
          "denoiseRadius": 12,
          "distanceFalloff": 1,
          "intensity": 0.09999999999999999,
          "denoiseIterations": 2,
          "renderMode": 0,
          "color": {
            "r": 0.5662006578947377,
            "g": 2.4589285714285656,
            "b": 10.7578125
          },
          "gammaCorrection": false,
          "logarithmicDepthBuffer": false,
          "screenSpaceRadius": false,
          "halfRes": false,
          "depthAwareUpsampling": true,
          "colorMultiply": true
        }

        if (tier === 2) {
          n8aoProps.aoRadius = 0.54

          n8aoProps.denoiseIterations = 2
          n8aoProps.denoiseSamples = 8
          n8aoProps.color = {
            "r": 0,
            "g": 0,
            "b": 0
          }
          n8aoProps.intensity = 2
          n8aoProps.distanceFalloff = 0.5
        } else if (tier === 1) {
          n8aoProps.aoRadius = 0.54

          n8aoProps.denoiseIterations = 2
          n8aoProps.denoiseSamples = 8
          n8aoProps.color = {
            "r": 0,
            "g": 0,
            "b": 0
          }
          n8aoProps.intensity = 7.88
          n8aoProps.distanceFalloff = 0.5
        }

        const props = n8aopass.configuration
        // create a tweakpane GUI for all the properties
        const pane = new Pane({ title: "N8AO" })
        pane.containerElem_.style.right = "8px"
        pane.containerElem_.style.top = "56px"

        for (const key in props) {
          if (key in n8aoProps) {
            props[key] = n8aoProps[key]
          }

          const bindingConfig = {
            min: 0,
            max: 25,
            step: ["aoSamples", "denoiseSamples", "renderMode", "denoiseIterations"].includes(key) ? 1 : 0.01,
          }

          pane.addBinding(props, key, bindingConfig)
        }

        // add a button that copies the current configuration to the clipboard
        pane.addButton({ title: "Copy configuration" }).on("click", () => {
          // make a shallow copy of the configuration object
          const props = { ...n8aopass.configuration }
          props.color = {
            r: props.color.r,
            g: props.color.g,
            b: props.color.b,
          }

          copy(JSON.stringify(props, null, 2))
        })
      }

      const traaEffect = new TRAAEffect(scene, camera, velocityDepthNormalPass)

      const lensDistortionEffect = new LensDistortionEffect({
        aberration: -1
      })

      const sharpnessEffect = new SharpnessEffect({
        sharpness: 1
      })

      const vignetteEffect = new VignetteEffect({
        offset: 0.5,
        darkness: 0.75
      })

      const bloomEffect = new BloomEffect({ mipmapBlur: true, luminanceThreshold: 0.1, intensity: 2, levels: 7 })

      if (tier > 2) {
        composer.addPass(new EffectPass(camera, sharpnessEffect, traaEffect))
        composer.addPass(new EffectPass(camera, lensDistortionEffect))
        composer.addPass(new EffectPass(camera, bloomEffect))
      } else {
        composer.addPass(new EffectPass(camera, sharpnessEffect, lensDistortionEffect, bloomEffect))
        composer.addPass(new EffectPass(camera, new FXAAEffect()))
      }


      const loadLut = async () => {
        const lutTexture = await new LUT3dlLoader().loadAsync("lut.3dl")

        const convertFloat32TextureToHalfFloat = texture => {
          texture.type = HalfFloatType

          const lutData = new Uint16Array(texture.image.data.length)
          const lutF32Data = texture.image.data

          for (let i = 0; i < lutData.length; i++) {
            lutData[i] = toHalfFloat(lutF32Data[i])
          }

          texture.image.data = lutData
        }

        convertFloat32TextureToHalfFloat(lutTexture)

        const lutEffect = new LUT3DEffect(lutTexture)
        composer.addPass(new EffectPass(camera, new ToneMappingEffect(), lutEffect, vignetteEffect))
      }

      loadLut()
    })()


    return () => {
      composer.removeAllPasses()
    }
  }, [composer, camera, scene])

  useFrame((state, delta) => {
    gl.autoClear = true // ?
    composer.render(delta)
  }, 1)
}
