import { Environment, Lightformer } from '@react-three/drei'
import { Canvas, useFrame } from '@react-three/fiber'
import { BallCollider, Physics, RigidBody } from '@react-three/rapier'
import { easing } from 'maath'
import { useMemo, useReducer, useRef } from 'react'
import * as THREE from 'three'
import { Effects } from './Effects'

const bubbleConfig = [
  ...Array.from({ length: 80 }, () => ({ color: '#444', roughness: 0.3, emissiveIntensity: 0, ior: 1 })),
  ...Array.from({ length: 22 }, () => ({ color: '#3e30fc', roughness: 0, ior: 1.4 })),
]

export default function App(props) {
  const [accent, click] = useReducer((state) => {
    // get mouse
  }, 0)
  const connectors = useMemo(() => bubbleConfig, [accent])
  return (
    <Canvas flat shadows onClick={click} dpr={[1, 1.5]} gl={{ antialias: false }} camera={{ position: [0, 0, 30], fov: 17.5, near: 10, far: 40 }} {...props}>
      <color attach="background" args={['#141622']} />
      <Physics timeStep="vary" gravity={[0, 0, 0]}>
        <Pointer />
        {connectors.map((props, i) => (
          <Sphere key={i} {...props} />
        ))}
      </Physics>
      <Environment resolution={256}>
        <group rotation={[-Math.PI / 3, 0, 1]}>
          <Lightformer form="circle" intensity={2} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={2} />
          <Lightformer form="circle" intensity={2} rotation-y={Math.PI / 2} position={[-5, -1, -1]} scale={2} />
          <Lightformer form="circle" intensity={2} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={8} />
          <Lightformer form="ring" color="#4060ff" intensity={80} onUpdate={(self) => self.lookAt(0, 0, 0)} position={[10, 10, 0]} scale={10} />
        </group>
      </Environment>
      <Effects />
    </Canvas>
  )
}

function Sphere({ position, children, vec = new THREE.Vector3(), scale, r = THREE.MathUtils.randFloatSpread, accent, color = 'white', ...props }) {
  const api = useRef()
  const ref = useRef()
  const pos = useMemo(() => position || [r(10), r(10), r(10)], [])
  useFrame((state, delta) => {
    delta = Math.min(0.1, delta)
    api.current?.applyImpulse(vec.copy(api.current.translation()).negate().multiplyScalar(0.05))
    easing.dampC(ref.current.material.color, color, 0.2, delta)

    const screenPos = ref.current.parent.position.clone().project(state.camera)
    const x = screenPos.x
    const y = screenPos.y

    const mousePos = state.mouse
    const distance = mousePos.distanceTo(screenPos)

    // get elapsed time from state
    const elapsedTime = state.clock.getElapsedTime()

    // set the color blue the more it's in the center
    let pulse = Math.cos(elapsedTime * 1.25 + x * 20 + y * 20) + 0.15
    pulse *= 0.375
    pulse = Math.max(Math.exp(-distance * 4), pulse)
    pulse = Math.max(0, pulse - 0.25)
    const c = (screenPos.x + (1 - screenPos.y) * 0.25 + 0.5) * pulse * 2

    const pulseColor = new THREE.Color(c * 0.1, c * 0.2, c)

    easing.dampC(ref.current.material.emissive, pulseColor, 0.075, delta)
  })

  const radius = 0.5

  return (
    <RigidBody linearDamping={4} angularDamping={10} friction={0.1} position={pos} ref={api} colliders={false}>
      <BallCollider args={[radius]} />
      <mesh ref={ref} castShadow receiveShadow>
        <sphereGeometry args={[radius, 64, 64]} />
        <meshPhysicalMaterial {...props} />
        {children}
      </mesh>
    </RigidBody>
  )
}

function Pointer({ vec = new THREE.Vector3() }) {
  const ref = useRef()
  useFrame(({ mouse, viewport }) => {
    ref.current?.setNextKinematicTranslation(vec.set((mouse.x * viewport.width) / 2, (mouse.y * viewport.height) / 2, 0))
  })
  return (
    <RigidBody position={[0, 0, 0]} type="kinematicPosition" colliders={false} ref={ref}>
      <BallCollider args={[1]} />
    </RigidBody>
  )
}
